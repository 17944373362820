#projects {
	padding: 5rem;
	position: relative;
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#projects-lower {
	margin-top: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#projects-lower h2 {
	text-align: center;
}

#extra-projects {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
	gap: 1rem;
}

@media (min-width: 1000px) {
	#extra-projects {
		width: 800px;
	}
}
