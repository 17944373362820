html {
	scroll-behavior: smooth;
}

body,
textarea {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	text-decoration: none;
}

h1 {
	color: var(--coffeebrown);
}

h2,
h3 {
	color: var(--mediumcoffeebrown);
}

p,
li {
	color: var(--lightcoffeebrown);
}

#root {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	background-color: var(--white);
	--lightwhite: #f7f6f6;
	--white: #f7f6f3;
	--lighttoastedwhite: #e8e4d9;
	--toastedwhite: #d8d3c2;
	--test: #dac9ba;
	--darktoastedwhite: #e9d2bc;
	--lattecream: #edd6c0;
	--darklattecream: #bd9c83;
	--lightcoffeebrown: #bbac9f;
	--mediumcoffeebrown: #a79485;
	--coffeebrown: #988373;
	--sage: #93ac8b;
}

#body-wrap {
	padding: 3rem 0;
	min-height: 100vh;
}

#all-pages {
	overflow: auto;
	scroll-snap-type: y mandatory;
}

.page {
	scroll-snap-align: start;
}

@media (max-width: 999px) {
	h1 {
		margin: 0;
		padding: 0;
		font-size: 2rem;
	}

	h2 {
		margin: 0;
		padding: 0;
		font-size: 1.5rem;
	}
}

@media (min-width: 1000px) {
	h1 {
		margin: 0;
		padding: 0;
		font-size: 7rem;
	}

	h2 {
		margin: 0;
		padding: 0;
		font-size: 2rem;
	}
}
