.project-card {
	position: relative;
	width: 220px;
	height: 220px;
	border: solid var(--coffeebrown) 1px;
	border-radius: 8px;
	box-shadow: var(--mediumcoffeebrown) 0px 1px 3px,
		var(--mediumcoffeebrown) 0px 1px 3px;
	background-color: var(--lightwhite);
	padding: 1rem;
	transition: 0.3s ease-in-out;
}

.project-card:hover {
	transform: scale(1.02);
	box-shadow: var(--mediumcoffeebrown) 0px 4px 4px,
		var(--mediumcoffeebrown) 0px 4px 6px;
}

.project-card > * {
	margin: 0;
	padding: 0;
}

.project-card p {
	margin-top: 0.5rem;
}

.tech-used {
	position: absolute;
	bottom: 0;
	display: flex;
	gap: 0.75rem;
	list-style-type: none;
	padding: 0;
	margin-bottom: 0.5rem;
}
