#footer-icons {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 1rem 2rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

#footer-icons img {
    margin: 0;
    padding: 0;
    height: 3rem;
    width: 3rem;
    transition: 0.2s ease-in-out;
}

@media (min-width: 1000px) {
    #footer-icons img:hover {
        transform: scale(1.2);
    }
}
