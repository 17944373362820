#home {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

#home-buttons {
	display: flex;
	align-items: center;
	text-align: center;
	margin: 2rem;
}

#contact-button {
	width: 110px;
	padding: 0.5rem 1rem;
	border-radius: 50px;
	cursor: pointer;
	color: var(--white);
	background-color: var(--lightcoffeebrown);
	border: solid 1px var(--toastedwhite);
	transition: 0.2s ease-in-out;
}

#home-buttons a {
	margin-left: 2rem;
	display: flex;
	position: relative;
	text-decoration: none;
}

#home-buttons img {
	margin: 0;
	padding: 0;
	height: 1.3rem;
	width: 1.3rem;
}

#home-buttons p {
	margin: 0 0 0 3px;
	padding: 0;
	color: var(--lightcoffeebrown);
	width: 100px;
}

@media (max-width: 999px) {
	#elevator-pitch {
		margin: 1rem 2rem;
		font-size: 0.9rem;
	}
}

@media (min-width: 1000px) {
	#home {
		scroll-snap-align: start;
	}

	#contact-button:hover {
		background-color: var(--mediumcoffeebrown);
		transform: scale(1.1);
	}

	#underlined-btn::before {
		content: '';
		position: absolute;
		left: 26px;
		bottom: 0;
		width: 0;
		height: 1px;
		background-color: var(--mediumcoffeebrown);
		transition: 0.2s ease-in;
	}

	#underlined-btn img {
		opacity: 0.7;
	}

	#underlined-btn:hover::before {
		width: 77%;
	}

	#underlined-btn:hover p {
		color: var(--mediumcoffeebrown);
	}

	#underlined-btn:hover img {
		opacity: 1;
	}

	#elevator-pitch {
		max-width: 60%;
		line-height: 2rem;
		font-size: 1.3rem;
	}
}
