#about {
	padding: 2rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}
#temp {
	position: absolute;
	top: 0;
	left: 0;
	transform: translateY(150%);
	text-align: center;
}

#about-top {
	position: relative;
	margin-bottom: 3rem;
}

#about h2 {
	position: relative;
	color: var(--coffeebrown);
}

#about-hook a {
	text-decoration: underline;
	color: var(--coffeebrown);
}

#values {
	margin-bottom: 1.5rem;
}

#about-bottom {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1rem;
}

#about-bottom div {
	display: flex;
	width: 250px;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

#about-bottom img {
	height: 3rem;
	width: 3rem;
}

#about-github {
	height: 2rem !important;
	width: 2rem !important;
	margin-bottom: 0.5rem !important;
}

@media (max-width: 1061px) {
	#about-top {
		margin: 2rem 1rem;
	}

	#about-img-box {
		display: none;
	}

	#about-bg-box {
		display: none;
	}
}

@media (min-width: 1062px) {
	#about-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 80%;
		margin: 0;
		padding: 0;
	}

	#about-top {
		margin-bottom: 4rem;
	}

	#about-hook {
		margin-right: 2rem;
		min-width: 400px;
	}

	#about-img-box {
		position: relative;
		min-height: 400px;
		min-width: 400px;
		background-color: var(--test);
		border: solid 2px var(--coffeebrown);
		border-radius: 8px;
		overflow: visible;
		z-index: 1;
	}

	#about-links {
		position: absolute;
		margin: 0 0.5rem;
		padding: 0;
		bottom: 0;
		right: 0;
	}

	#about-links img {
		margin: 0;
		padding: 0;
		height: 3rem;
		width: 3rem;
		transition: 0.2s ease-in-out;
	}

	#about-links img:hover {
		transform: scale(1.1);
	}

	#about-bg-box {
		position: absolute;
		min-height: 400px;
		min-width: 400px;
		right: -10px;
		top: 10px;
		border: solid 2px var(--coffeebrown);
		border-radius: 8px;
		background-color: var(--lightcoffeebrown);
	}

	#dot1 {
		position: absolute;
		left: 10px;
		top: 10px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: var(--coffeebrown);
		z-index: 2;
	}

	#dot2 {
		position: absolute;
		left: 10px;
		top: 25px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: var(--coffeebrown);
		z-index: 2;
	}

	#about-bottom * {
		margin: 0.25rem 1rem;
	}
}
