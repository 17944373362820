nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 3rem;
	border-bottom: solid var(--toastedwhite);
	background-color: rgb(247, 246, 243, 0.98);
	z-index: 2;
}

nav * {
	margin: 0 1rem;
	color: var(--coffeebrown);
}

#nav-open {
	display: none;
	height: 2rem;
	width: 2rem;
}

#nav-close {
	display: none;
	margin-right: 1.3rem;
	height: 1.3rem;
	width: 1.3rem;
}

#form-close {
	display: none;
	margin-right: 1.3rem;
	height: 1.3rem;
	width: 1.3rem;
}

#nav-links a {
	position: relative;
	margin: 0 1rem;
	text-decoration: none;
}

@media (max-width: 999px) {
	#nav-open {
		display: block;
	}

	#nav-links {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		margin: 0;
		margin-top: 3rem;
		min-width: 100vw;
		min-height: 100vh;
		background-color: var(--white);
		border-top: solid var(--toastedwhite);
		top: 0;
		right: 0;
		transform: translateX(100%);
		transition: 0.5s ease-in-out;
		z-index: 2;
		gap: 3rem;
	}
}

@media (min-width: 1000px) {
	#nav-links a::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0;
		height: 1px;
		background-color: var(--coffeebrown);
		transition: 0.2s ease-in;
	}

	#nav-links a:hover::before {
		width: 100%;
	}
}
