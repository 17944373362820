#contactForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 9rem;
}

#contactTop {
	text-align: center;
}

#contact h2 {
	color: var(--coffeebrown);
}

#contactInputs {
	display: flex;
	flex-direction: column;
}

#contactInputs input,
textarea {
	outline: none;
	resize: none;
	color: var(--mediumcoffeebrown);
	background-color: var(--lightwhite);
	border: solid var(--mediumcoffeebrown) 1px;
	border-radius: 16px;
	padding: 0.5rem;
	margin-bottom: 1.5rem;
	font-size: 16px;
}

#contactInputs .inputContainer {
	display: flex;
	flex-direction: column;
	font-size: 16px;
}

#contactInputs input:focus,
textarea:focus {
	color: var(--coffeebrown);
	border: solid var(--coffeebrown) 1px;
}

#contactInputs input:focus-within ::placeholder {
	color: var(--coffeebrown) !important;
}

::placeholder {
	color: var(--mediumcoffeebrown);
	font-size: 16px;
}

#contactBtn {
	width: 75px;
	padding: 0.5rem 1rem;
	border-radius: 50px;
	cursor: pointer;
	color: var(--white);
	background-color: var(--lightcoffeebrown);
	border: solid 1px var(--toastedwhite);
	transition: 0.2s ease-in-out;
}

@media (max-width: 683px) {
	#contactForm {
		padding: 0 3rem;
		height: 80vh;
	}

	#contactTop {
		margin-bottom: 1.5rem;
	}

	#contactTop * {
		margin: 0;
	}

	#contactTop h2 {
		margin-bottom: 0.5rem;
	}

	#contactInputs {
		width: 100%;
	}
	#contactInfo {
		display: flex;
		flex-direction: column;
	}
}

@media (min-width: 684px) {
	#contactTop {
		max-width: 420px;
		margin-bottom: 3rem;
	}
	#contactInfo {
		display: flex;
		justify-content: space-between;
	}

	#contactInputs {
		min-width: 600px;
	}

	#contactInfo input {
		width: 40%;
	}

	#contactBtn:hover {
		transform: scale(1.1);
		background-color: var(--mediumcoffeebrown);
	}
}
